// @flow

// RESTful API
const API_ROOT_URL = 'https://api.freyre.com.ar';

export const ENDPOINT_GET_POSTS_BY_PAGE = `${API_ROOT_URL}/posts/page`;
export const ENDPOINT_GET_POST_BY_ID = `${API_ROOT_URL}/posts/`;
export const ENDPOINT_GET_PAGES = `${API_ROOT_URL}/pages`;
export const ENDPOINT_GET_USEFUL_PHONES = `${ENDPOINT_GET_PAGES}/telefonos`;
export const ENDPOINT_GET_ABOUT = `${ENDPOINT_GET_PAGES}/institucional`;
export const ENDPOINT_GET_ROSTER = `${API_ROOT_URL}/roster`;
export const ENDPOINT_GET_PHARMACY = `${API_ROOT_URL}/pharmacy-roster-today`;
export const ENDPOINT_GET_WEATHER = `${API_ROOT_URL}/weather`;
export const ENDPOINT_GET_ADS = `${API_ROOT_URL}/ads`;
export const ENDPOINT_GET_CLASSIFIEDS = `${API_ROOT_URL}/classifieds`;
export const ENDPOINT_GET_OBITUARIES = `${API_ROOT_URL}/obituaries`;
export const ENDPOINT_CONTACT_US = `${API_ROOT_URL}/messages`;

// Email address for contact details and form
export const EMAIL_ADDRESS = 'canal6@freyre.com.ar';
