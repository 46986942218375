// @flow
import React from 'react';
import type { WeatherType } from '../../../types';
import './styles.css';

// Meteogram image source from Yr.no
const meteogramUrl = 'https://www.yr.no/place/Argentina/Córdoba/Freyre/avansert_meteogram.png';

export default function Details(props: WeatherType) {
  const { currentConditions } = props;
  const dataAvailable = !!currentConditions;

  if (!dataAvailable) {
    return <div className="Weather-Details__spinner" />;
  }

  return (
    <div className="Weather-Details">
      <div className="Weather-Details__header">
        <div className="Weather-Details__overview">
          <div className="Weather-Details__highlight">
            <div className="Weather-Details__highlight-value">
              {currentConditions.currentTemperature}
            </div>
            <div className="Weather-Details__highlight-label">Temperatura</div>
          </div>

          <div className="Weather-Details__boxes">
            <span className="Weather-Details__box">
              <span className="Weather-Details__box-label">Mínima Hoy</span>
              <span className="Weather-Details__box-value Weather-Details__box-value--low">
                {currentConditions.minTemperature}
              </span>
            </span>

            <span className="Weather-Details__box">
              <span className="Weather-Details__box-label">Máxima Hoy</span>
              <span className="Weather-Details__box-value Weather-Details__box-value--high">
                {currentConditions.maxTemperature}
              </span>
            </span>
          </div>
        </div>

        <div className="Weather-Details__icon-wrapper">
          <img
            className="Weather-Details__icon"
            src={currentConditions.iconUrl}
            alt={currentConditions.iconDescription}
            title={currentConditions.iconDescription}
          />
        </div>
      </div>

      <ul className="Weather-Details__list">
        <li className="Weather-Details__list-item">
          <strong>Humedad:</strong> {currentConditions.humidity}
        </li>
        <li className="Weather-Details__list-item">
          <strong>Presión:</strong> {currentConditions.pressure}
        </li>
        <li className="Weather-Details__list-item">
          <strong>Viento:</strong> {currentConditions.windSpeed}
        </li>
      </ul>

      <h2 className="Weather-Details__subtitle">Meteograma (próximas 48 horas)</h2>
      <a
        className="Weather-Details__meteogram-link"
        href={meteogramUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="Weather-Details__meteogram"
          alt="Meteograma de las próximas 48 horas"
          src={meteogramUrl}
        />
      </a>
    </div>
  );
}
